import React from 'react';
import Label from 'components/atoms/labels/text-and-icon';
import { Typography } from 'components/atoms/typography';
import { ORDER_STATUS } from 'helpers/types';
import CheckIcon from 'public/icons/figma/check.svg';
import TimeIcon from 'public/icons/figma/time.svg';
import { OrderDetailInfoHeaderBox } from './styles';

type TProps = {
  title: string;
  variant: 'grey' | 'green';
  status: ORDER_STATUS;
};

const OrderDetailInfoHeader = ({ title, variant, status }: TProps) => {
  const renderLabel = () => {
    switch (variant) {
      case 'grey':
        return (
          <Label icon={<TimeIcon />} variant="white-border">
            {status}
          </Label>
        );
      case 'green':
        return (
          <Label icon={<CheckIcon />} variant="grey-200">
            {status}
          </Label>
        );
    }
  };
  return (
    <OrderDetailInfoHeaderBox>
      <Typography variant="h3">{title}</Typography>
      {renderLabel()}
    </OrderDetailInfoHeaderBox>
  );
};

export default OrderDetailInfoHeader;
