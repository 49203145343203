import { useState } from 'react';
import { formatDate } from 'date-fns';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import Drawer from 'components/atoms/drawers/drawer-button';
import { ImageBg } from 'components/atoms/image';
import { Typography } from 'components/atoms/typography';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import CloseIcon from 'public/icons/figma/close-small.svg';
import ExternalLinkIcon from 'public/icons/figma/external-link.svg';
import LocationIcon from 'public/icons/figma/location.svg';
import MailIcon from 'public/icons/figma/mail.svg';
import PhoneIcon from 'public/icons/figma/phone.svg';
import TimeIcon from 'public/icons/figma/time.svg';
import {
  DrawerContactDispensaryAddressSection,
  DrawerContactDispensaryBody,
  DrawerContactDispensaryDetail,
  DrawerContactDispensaryDrawerDaySection,
  DrawerContactDispensaryDrawerTimeSection,
  DrawerContactDispensaryDrawercontainer,
  DrawerContactDispensaryFlexCenter,
  DrawerContactDispensaryHeader,
  DrawerContactDispensaryImageContainer,
  DrawerContactDispensaryOpenHourContent,
  DrawerContactDispensaryOpenHoursContainer,
  DrawerContactDispensaryOpenHoursInfoSection,
  DrawerContactDispensaryOpenHoursTitleSection,
} from './styles';

interface openHourProps {
  day: string;
  hours: string;
}

interface ContactDispensaryDrawerProps {
  label?: string;
  dispensaryName?: string;
  openHourLabel?: string;
  openHours?: openHourProps[];
  phoneNumber?: string;
  phoneLabel?: string;
  address?: string;
  addressLink?: string;
  addressLabel?: string;
  contactLink?: string;
  contactLabel?: string;
  url?: string;
  alt?: string;
  ariaLabel?: string;
  host?: string;
}
interface ContactDispensaryDrawerLinkProps {
  children: any;
  href: string;
  className?: string;
}

const ContactDispensaryDrawerLink = (props: any) => {
  const { children, href = '/', className = '' }: ContactDispensaryDrawerLinkProps = props;
  return (
    <CommonButton
      {...props}
      as="a"
      button-type={'tertiary'}
      color={'green'}
      className={'flex items-center !gap-[6px] !p-0 ' + className}
      href={href}
    >
      {children}
    </CommonButton>
  );
};

const ContactDispensaryDrawerDetail = ({ openHours }: { openHours: openHourProps[] }) => {
  const today = formatDate(new Date(), 'EEEE');
  const openHourContent = openHours.map(({ day, hours }) => {
    const currentDay = today === day ? ' !font-bold !text-grey-500' : '!text-grey-400';
    const selectedDay = today === day ? 'selected-day' : '';
    return (
      <DrawerContactDispensaryDrawercontainer key={day + hours}>
        <DrawerContactDispensaryDrawerDaySection>
          <Typography variant="body-small" className={currentDay} data-testid={selectedDay}>
            {day}
          </Typography>
        </DrawerContactDispensaryDrawerDaySection>
        <DrawerContactDispensaryDrawerTimeSection>
          <Typography variant="body-small" className={currentDay}>
            {hours}
          </Typography>
        </DrawerContactDispensaryDrawerTimeSection>
      </DrawerContactDispensaryDrawercontainer>
    );
  });
  return <DrawerContactDispensaryOpenHourContent>{openHourContent}</DrawerContactDispensaryOpenHourContent>;
};

const ContactDispensaryDrawer = ({
  host = '',
  label = 'Contact dispensary',
  dispensaryName = '',
  openHourLabel = 'Opening hours',
  openHours = [],
  phoneNumber = '',
  phoneLabel = 'Call now',
  address = '',
  addressLink = '/',
  addressLabel = 'View on Maps',
  contactLabel = 'Contact Us',
  contactLink = '/contact',
  url = '',
  alt,
  ariaLabel,
}: ContactDispensaryDrawerProps) => {
  const [open, setOpen] = useState(false);
  const closeDrawer = () => setOpen(false);
  return (
    <>
      <Drawer
        open={open}
        className={open ? 'rounded-t-md lg:rounded-t-none' : 'hidden'}
        onChange={(status) => setOpen(status)}
        label={label}
      >
        {/* Header */}
        <DrawerContactDispensaryHeader>
          <Typography variant="body-small" className="capitalize !text-grey-400 [flex:_1_0_0]">
            {label}
          </Typography>
          <IconButton data-testid="close-button-icon" onClick={closeDrawer} button-type={'primary'} color={'white'}>
            <CloseIcon color="#6F7263" />
          </IconButton>
        </DrawerContactDispensaryHeader>
        {/* Body */}
        <DrawerContactDispensaryBody>
          <Typography variant="h5" className="!self-stretch !text-grey-500">
            {dispensaryName}
          </Typography>
          <DrawerContactDispensaryDetail>
            {/* open hour */}
            <DrawerContactDispensaryFlexCenter className="flex-col !gap-[20px] lg:flex-row">
              <DrawerContactDispensaryOpenHoursContainer>
                <DrawerContactDispensaryOpenHoursTitleSection>
                  <TimeIcon className="text-grey-400" />
                  <Typography variant="body-small" className="!text-grey-400">
                    {openHourLabel}
                  </Typography>
                </DrawerContactDispensaryOpenHoursTitleSection>
                <DrawerContactDispensaryOpenHoursInfoSection>
                  <ContactDispensaryDrawerDetail openHours={openHours} />
                </DrawerContactDispensaryOpenHoursInfoSection>
              </DrawerContactDispensaryOpenHoursContainer>
              <DrawerContactDispensaryImageContainer>
                <ImageBg
                  src={url}
                  alt={alt || ''}
                  ariaLabel={ariaLabel || ''}
                  className={'!shrink-0 rounded-md lg:!h-[128px] lg:!w-[188px]'}
                />
              </DrawerContactDispensaryImageContainer>
            </DrawerContactDispensaryFlexCenter>
            {/* Call now */}
            <DrawerContactDispensaryFlexCenter>
              <PhoneIcon color="#6F7263" height={24} width={24} />
              <Typography variant="body-small" className="!text-grey-400">
                {phoneNumber}
              </Typography>
              <ContactDispensaryDrawerLink href={`tel:${phoneNumber}`}>
                {phoneLabel} <ChevronRightIcon height={24} width={24} color="#00461e" />
              </ContactDispensaryDrawerLink>
            </DrawerContactDispensaryFlexCenter>
            {/* View on Maps */}
            <DrawerContactDispensaryFlexCenter className="flex-row !items-start">
              <LocationIcon color="#6F7263" height={24} width={24} />
              <DrawerContactDispensaryAddressSection>
                <Typography variant="body-small" className="!text-grey-400">
                  {address}
                </Typography>
                <ContactDispensaryDrawerLink
                  href={addressLink}
                  className="inline-flex"
                  aria-label={`${dispensaryName} Google map link`}
                  target={'_blank'}
                >
                  {addressLabel} <ExternalLinkIcon height={24} width={24} color="#00461e" />
                </ContactDispensaryDrawerLink>
              </DrawerContactDispensaryAddressSection>
            </DrawerContactDispensaryFlexCenter>
            {/* Contact us */}
            <DrawerContactDispensaryFlexCenter>
              <MailIcon color="#6F7263" height={24} width={24} />
              <ContactDispensaryDrawerLink href={contactLink === '/contact' ? host + contactLink : contactLink}>
                {contactLabel}
                {contactLink.includes(host) ? (
                  <ChevronRightIcon data-testid="internal-icon" height={24} width={24} color="#00461e" />
                ) : (
                  <ExternalLinkIcon data-testid="external-icon" height={24} width={24} color="#00461e" />
                )}
              </ContactDispensaryDrawerLink>
            </DrawerContactDispensaryFlexCenter>
            {/* End of Contact Us */}
          </DrawerContactDispensaryDetail>
        </DrawerContactDispensaryBody>
        {/* End of Body */}
      </Drawer>
    </>
  );
};

export default ContactDispensaryDrawer;
