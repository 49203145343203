import tw from 'tailwind-styled-components';

export const OrderDetailInfoHeaderBox = tw.div`
    flex 
    w-full 
    flex-row 
    items-start 
    justify-between 
    gap-8 
    border-b 
    border-grey-200 
    pb-6  
    md:flex-col 
    md:border-b-0
    md:pb-0
`;
