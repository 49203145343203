import { useMemo, useState } from 'react';
import { formatDate } from 'date-fns';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import { Typography } from 'components/atoms/typography';
import ProductCard from 'components/molecules/cards/product-card';
import { WEIGHT_OBJECT } from 'helpers/constants/product';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { ORDER_STATUS_MAPPING } from 'helpers/types';
import { getThcValue } from 'helpers/utils/get-products-fields';
import { tablet } from 'helpers/utils/screensizes';
import AlertIcon from 'public/icons/figma/alert.svg';
import ChevronLeftIcon from 'public/icons/figma/chevron-left.svg';
import { useAppSelector } from 'redux/hooks';
import OrderDetailInfoCard from './order-detail-info-card';
import OrderDetailInfoFooter from './order-detail-info-footer';
import OrderDetailInfoHeader from './order-detail-info-header';
import {
  OrderDetailContainer,
  OrderDetailIconWithTextBox,
  OrderDetailInfoBox,
  OrderDetailProductCardBox,
  OrderDetailWrapper,
} from './styles';

type TProps = {
  footerTitle?: string;
  footerButtonTitle?: string;
  headerButtonTitle?: string;
  showWarningMessage?: boolean;
  orderData: any;
};

const OrderDetail = ({
  headerButtonTitle,
  footerTitle = 'Need help with your order?',
  footerButtonTitle = 'Contact dispensary',
  showWarningMessage,
  orderData,
}: TProps) => {
  const [isTablet] = useMediaQuery(tablet);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { checked_out_time: _, status, id, store_name, products, store_id } = orderData || {};

  const showGreenButton =
    ORDER_STATUS_MAPPING[status] === ORDER_STATUS_MAPPING.delivery_started ||
    ORDER_STATUS_MAPPING[status] === ORDER_STATUS_MAPPING.delivery_finished ||
    ORDER_STATUS_MAPPING[status] === ORDER_STATUS_MAPPING.ready_for_pickup ||
    ORDER_STATUS_MAPPING[status] === ORDER_STATUS_MAPPING.finished ||
    ORDER_STATUS_MAPPING[status] === ORDER_STATUS_MAPPING.with_review ||
    ORDER_STATUS_MAPPING[status] === ORDER_STATUS_MAPPING.finished_without_review;

  const orderTotal = products.reduce(
    (accumulator, currentValue) => accumulator + +currentValue?.discounted_checkout_price,
    0,
  );
  const dayAndDate = ` ${formatDate(new Date(), 'EEEE')} ${formatDate(new Date(), 'MM.dd.yy')}`;
  const { dispensaryList } = useAppSelector((state) => state?.dispensaryList);
  const [dispensaryInfo, setDispensaryInfo] = useState(null);
  const [stateName, setStateName] = useState(null);

  useMemo(() => {
    dispensaryList.map(({ dispensaries, state: stateData }: any) => {
      dispensaries.map((dispensary) => {
        if (dispensary?.menusCollection?.items[0]?.menuSourceId == store_id) {
          setDispensaryInfo(dispensary);
          setStateName(stateData?.state?.stateName);
        }
      });
    });
  }, [dispensaryList, store_id]);

  return (
    <OrderDetailContainer>
      <OrderDetailIconWithTextBox>
        <IconWithTextButton
          color="green"
          size="lg"
          button-type="tertiary"
          iconPosition="before"
          icon={<ChevronLeftIcon />}
          className="!p-0"
          as="a"
          href={'/account/orders/'}
        >
          {headerButtonTitle}
          <Typography variant="body-small-bold" className="pt-1">
            Back to My orders
          </Typography>
        </IconWithTextButton>
      </OrderDetailIconWithTextBox>
      <OrderDetailWrapper>
        <OrderDetailInfoBox>
          <OrderDetailInfoHeader
            title={dayAndDate}
            variant={showGreenButton ? 'green' : 'grey'}
            status={ORDER_STATUS_MAPPING[status]}
          />
          <OrderDetailInfoCard
            title={`Order #${id} details`}
            dateTitle={dayAndDate}
            dispensaryTitle={store_name}
            orderTotalTitle={`$${orderTotal} USD`}
            // TODO: FIXME once this key is available in api response
            paymentMethordTitle="Credit card"
            showMessage={showWarningMessage}
            messageIcon={<AlertIcon className="text-resin" />}
            messageTitle="Some of the products may be delivered later than other ones since they are coming from another dispensary."
          />
          {isTablet && (
            <OrderDetailInfoFooter
              stateName={stateName || ''}
              dispensaryInfo={dispensaryInfo || {}}
              title={footerTitle}
              buttonText={footerButtonTitle}
            />
          )}
        </OrderDetailInfoBox>
        <OrderDetailProductCardBox>
          {products &&
            products?.map((item, index) => (
              <ProductCard
                position={index}
                key={index}
                variant="horizontal"
                brandName={item?.brand}
                name={item?.name}
                type={item?.brand_subtype}
                price={item?.discounted_checkout_price}
                image={item?.image_urls ? item?.image_urls[0] : ''}
                thc={getThcValue(item) || ''}
                // TODO: FIXME for edible... category
                gram={item?.amount || item?.available_weights ? WEIGHT_OBJECT[item?.available_weights[0]] : ''}
                hideGram={ORDER_STATUS_MAPPING[status] === 'Complete' && !item?.available}
                showOutOfStock={ORDER_STATUS_MAPPING[status] === 'Complete' && !item?.available}
                hidePrice={ORDER_STATUS_MAPPING[status] === 'Complete' && !item?.available}
                hideAddToCart={ORDER_STATUS_MAPPING[status] !== 'Complete'}
                disableAddToCartBtn={ORDER_STATUS_MAPPING[status] == 'Complete' && !item?.available}
                // @ts-ignore
                url={`${dispensaryInfo?.menusCollection?.items?.[0]?.linkedFrom?.pageMenuCollection?.items?.[0]?.slug}product/${item?.product_id}/${item?.unique_slug}/`}
              />
            ))}
        </OrderDetailProductCardBox>
        {!isTablet && (
          <OrderDetailInfoFooter
            stateName={stateName || ''}
            dispensaryInfo={dispensaryInfo || {}}
            title={footerTitle}
            buttonText={footerButtonTitle}
          />
        )}
      </OrderDetailWrapper>
    </OrderDetailContainer>
  );
};

export default OrderDetail;
