import tw from 'tailwind-styled-components';

export const OrderDetailInfoListBox = tw.div`
    items-cetner 
    flex 
    flex-row 
    justify-between 
    gap-4 
    border-b 
    border-grey-200
    py-4
`;

export const OrderDetailInfoListTitleBox = tw.div`
    items-cetner 
    flex 
    flex-row 
    gap-4
`;
