import React from 'react';
import { Typography } from 'components/atoms/typography';
import { OrderDetailInfoListBox, OrderDetailInfoListTitleBox } from './styles';
type TProps = {
  icon: JSX.Element;
  title: string;
  subTitle: string;
};
const OrderDetailInfoListCard = ({ icon, title, subTitle }: TProps) => {
  return (
    <OrderDetailInfoListBox>
      {(icon || title) && (
        <OrderDetailInfoListTitleBox>
          {icon}
          {title && (
            <Typography variant="body-small" className="!text-grey-400">
              {title}
            </Typography>
          )}
        </OrderDetailInfoListTitleBox>
      )}
      {subTitle && (
        <Typography variant="body-small-bold" className="text-right !text-grey-400">
          {subTitle}
        </Typography>
      )}
    </OrderDetailInfoListBox>
  );
};

export default OrderDetailInfoListCard;
