import tw from 'tailwind-styled-components';

export const OrderDetailWarningMessageBox = tw.div`
    flex 
    md:px-5 
    md:py-4 
    p-3 
    items-center 
    gap-3 
    md:gap-5 
    self-stretch 
    rounded-md 
    bg-grey-100 
`;

export const OrderDetailWarningMessageIconBox = tw.div`
    h-[24px] 
    w-[24px] 
    flex 
    items-center
`;
