'use client';

import { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import OrderDetail from 'components/organisms/accounts/order-detail';
import SideBar from 'components/organisms/drawers/sidebar';
import Loading from 'components/organisms/global/loader';
import { productMenuIndex } from 'helpers/algolia/clients';
import janeExternalUserApi from 'helpers/jane-api';
import janeStoreApi from 'helpers/jane-store-operations-api';
import { TSelectedSubItem } from 'helpers/types';
import { useAppSelector } from 'redux/hooks';

const AccountOrderTastic = () => {
  const [data, setData] = useState(null);
  const user = useAppSelector((store) => store.accountReducer.user);
  const [orderDetailApiState, setOrderDetailApiState] = useState({
    loading: false,
    err: '',
  });

  const router = useRouter();
  const path = usePathname();

  useEffect(() => {
    const urlArr = path.split('orders/');
    const orderId = urlArr[urlArr.length - 1].replace('/', '');
    const callOrderDetailApi = async () => {
      try {
        setOrderDetailApiState({ loading: true, err: '' });
        const res: any = await janeExternalUserApi.getOrderById(orderId);
        const data = res?.data?.data?.reservation;
        const duplicateProductIdsCount = {};
        const uniqueProductIds = data?.products?.filter((item, index, arr) => {
          const value = item?.product_id;
          if (arr.indexOf(value) !== index) {
            duplicateProductIdsCount[value] = duplicateProductIdsCount[value] ? ++duplicateProductIdsCount[value] : 1;
          }
          return arr.indexOf(value) === index;
        });

        const productIdFilters = uniqueProductIds?.map((item, i) => {
          if (i === 0) {
            return `product_id = ${item}`;
          } else {
            return ` OR product_id = ${item}`;
          }
        });

        const productDataFromAlgolia = await productMenuIndex.search('', {
          facets: ['*'],
          filters: productIdFilters.join(''),
        });
        // const updatedProductDataFromAlgolia = {}...productDataFromAlgolia];

        if (data?.products?.length !== uniqueProductIds) {
          const duplicateIds = Object.keys(duplicateProductIdsCount);
          const updatedHits = productDataFromAlgolia?.hits?.flatMap((item: any) => {
            if (duplicateIds?.indexOf(item?.product_id)) {
              return Array(duplicateProductIdsCount[item?.product_id]).fill(item);
            }
            return item;
          });
          productDataFromAlgolia.hits = updatedHits;
        }

        let updatedProductData = data?.products;

        if (data?.products?.length !== productDataFromAlgolia?.hits?.length) {
          const productIds = data?.products?.map((item) => item?.product_id);
          const foundProductIdsInAlgolia = Object.keys(productDataFromAlgolia?.facets?.product_id as unknown as string);
          const notFoundProductIdsInAloglia = productIds.filter(
            (item) => !foundProductIdsInAlgolia?.includes(item.toString()),
          );
          const productsDataFromJane = await Promise.all(
            notFoundProductIdsInAloglia.map(async (productId) => {
              const res = await janeStoreApi.getJaneProduct(data?.store_id, productId.toString());
              return res?.data?.data?.menu_product;
            }),
          );
          const result = data?.products?.map((item) => {
            const indexInAlgolia = productIds.findIndex((id) => foundProductIdsInAlgolia.includes(id));
            const indexInJane = productIds.findIndex((id) => notFoundProductIdsInAloglia.includes(id));
            if (indexInAlgolia !== -1) {
              // update with algolia data
              return {
                ...productDataFromAlgolia?.hits[indexInAlgolia],
                ...item,
                available: true,
              };
            } else if (indexInJane !== -1) {
              // update with jane data
              return {
                ...productsDataFromJane[indexInJane],
                ...item,
                available: false,
              };
            }
          });
          updatedProductData = result;
        } else {
          const result = data?.products?.map((item, i) => {
            return {
              ...productDataFromAlgolia?.hits[i],
              ...item,
              available: true,
            };
          });
          updatedProductData = result;
        }
        const updatedData = {
          ...data,
          products: updatedProductData,
        };
        setOrderDetailApiState({ loading: false, err: '' });
        setData(updatedData);
      } catch (error) {
        setOrderDetailApiState({ loading: false, err: error });
        router.push('/404');
      }
    };
    if (user) callOrderDetailApi();
  }, [user]);

  return (
    <div className="md:flex">
      {orderDetailApiState.loading && <Loading showbackdrop />}
      <SideBar selectedItem={TSelectedSubItem.ORDERS} />
      {data && <OrderDetail orderData={data} />}
    </div>
  );
};

export default AccountOrderTastic;
