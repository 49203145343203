import React from 'react';
import { Typography } from 'components/atoms/typography';
import BagMediumIcon from 'public/icons/figma/bag-medium.svg';
import OrderDetailInfoListCard from './order-detail-info-list-card';
import OrderDetailWarningMessage from './order-detail-warning-message';
import { OrderDetailInfoBox, OrderDetailInfoListWrapper } from './styles';
type TProps = {
  showMessage?: boolean;
  messageTitle?: string;
  messageIcon?: JSX.Element;
  dateTitle: string;
  dispensaryTitle: string;
  orderTotalTitle: string;
  paymentMethordTitle: string;
  title: string;
};
import LocationIcon from 'public/icons/figma/location.svg';
import EventIcon from 'public/icons/figma/event.svg';

const OrderDetailInfoCard = ({
  title,
  showMessage,
  messageTitle,
  messageIcon,
  dateTitle,
  dispensaryTitle,
  orderTotalTitle,
}: TProps) => {
  const orderDetailInfoData = [
    {
      title: 'Date placed',
      subTitle: dateTitle,
      icon: <EventIcon className="!text-grey-400" />,
    },
    {
      title: 'Dispensary',
      subTitle: dispensaryTitle,
      icon: <LocationIcon className="!text-grey-400" />,
    },
    {
      title: 'Order total',
      subTitle: orderTotalTitle,
      icon: <BagMediumIcon className="!text-grey-400" />,
    },
    // {
    //   title: 'Payment method',
    //   subTitle: paymentMethordTitle,
    //   icon: <MdOutlineCreditCard className="!text-grey-400" />,
    // },
  ];
  return (
    <OrderDetailInfoBox>
      {title && <Typography variant="body-small-bold">{title}</Typography>}
      <OrderDetailInfoListWrapper>
        <div>
          {orderDetailInfoData?.map((item, index) => (
            <OrderDetailInfoListCard {...item} key={index} />
          ))}
        </div>
        {showMessage && <OrderDetailWarningMessage icon={messageIcon} title={messageTitle as string} />}
      </OrderDetailInfoListWrapper>
    </OrderDetailInfoBox>
  );
};

export default OrderDetailInfoCard;
