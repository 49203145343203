import React from 'react';
import { Typography } from 'components/atoms/typography';
import { OrderDetailWarningMessageBox, OrderDetailWarningMessageIconBox } from './styles';
type TProps = {
  icon?: JSX.Element;
  title: string;
};
const OrderDetailWarningMessage = ({ icon, title }: TProps) => {
  return (
    <OrderDetailWarningMessageBox data-testid="order-detail-warning-message">
      {icon && <OrderDetailWarningMessageIconBox data-testid="warning-icon">{icon}</OrderDetailWarningMessageIconBox>}
      {title && (
        <Typography variant="body-small-bold" className="!text-grey-500">
          {title}
        </Typography>
      )}
    </OrderDetailWarningMessageBox>
  );
};

export default OrderDetailWarningMessage;
