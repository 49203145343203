import tw from 'tailwind-styled-components';

export const OrderDetailInfoBox = tw.div`
    flex 
    w-full 
    flex-col 
    items-start 
    gap-4
`;

export const OrderDetailInfoListWrapper = tw.div`
    flex 
    flex-col 
    w-full 
    md:w-[80%] 
    gap-8
`;
