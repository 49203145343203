import React from 'react';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import { Typography } from 'components/atoms/typography';
import ContactDispensaryDrawer from 'components/organisms/drawers/contact-dispensary';
import { DayMap } from 'helpers/constants/misc';
import { useChatEventTracking } from 'helpers/hooks/use-chat-event-tracking';
import { getDispensaryAddress } from 'helpers/utils/convert-to-dispensary-page-type';
import { getOpeningTime } from 'helpers/utils/convert-to-dispensary-type';
import ChatIcon from 'public/icons/rise-icons/Chat.svg';
import { OrderDetailInfoFooterBox } from './styles';
type TProps = {
  stateName: string;
  dispensaryInfo: Record<string, any>;
  title: string;
  buttonText: string;
};

const OrderDetailInfoFooter = ({ stateName, dispensaryInfo, title, buttonText }: TProps) => {
  const { openChatEvent } = useChatEventTracking();
  const { dispensaryInformation, heroImage } = dispensaryInfo || {};

  return (
    <OrderDetailInfoFooterBox>
      {title && <Typography variant="h5">{title}</Typography>}
      <ContactDispensaryDrawer
        dispensaryName={dispensaryInformation?.title}
        openHours={getOpeningTime(dispensaryInformation?.hoursInformationCollection.items).map((item) => ({
          //@ts-ignore
          ...item,
          //@ts-ignore
          day: DayMap[item?.day.toLowerCase()],
        }))}
        address={getDispensaryAddress(dispensaryInformation, stateName)}
        addressLabel={dispensaryInformation?.mapCtaText}
        addressLink={dispensaryInformation?.mapUrl}
        phoneNumber={dispensaryInformation?.contactNumber}
        url={heroImage?.url}
        alt={heroImage?.title}
        label={buttonText}
      />
      <IconWithTextButton
        color="green"
        button-type="secondary"
        iconPosition="before"
        size="sm"
        className="md:hidden"
        icon={<ChatIcon />}
        onClick={openChatEvent}
      >
        Chat with us
      </IconWithTextButton>
    </OrderDetailInfoFooterBox>
  );
};

export default OrderDetailInfoFooter;
