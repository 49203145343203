import tw from 'tailwind-styled-components';

export const DrawerContactDispensaryHeader = tw.div`
    flex
    justify-center
    items-center
    self-stretch
    p-5
    gap-2.5
    lg:gap-6
    lg:p-[48px_60px_40px_60px]
`;

export const DrawerContactDispensaryBody = tw.div`
    flex
    flex-col
    items-start
    border-grey-200

    border-t
    gap-5
    !h-[476px]
    w-full
    p-[24px_20px_0px_20px]

    lg:gap-8
    lg:!h-full
    lg:p-[56px_40px_0px_40px]

`;

export const DrawerContactDispensaryDetail = tw.div`
    flex
    flex-col
    items-start
    w-full
    gap-5
`;

export const DrawerContactDispensaryFlexCenter = tw.div`
    flex
    items-center
    self-stretch
    gap-3
`;

export const DrawerContactDispensaryOpenHourContent = tw.div`
    inline-flex
    pb-0
    flex-col
    items-start
    gap-4
`;

export const DrawerContactDispensaryImageContainer = tw.div`
    hidden
    lg:flex
    lg:h-full
    lg:w-full
    lg:[flex:1_0_0]
`;

export const DrawerContactDispensaryOpenHoursContainer = tw.div`
    flex
    flex-col
    w-full
    items-start
    justify-start
    gap-5
`;

export const DrawerContactDispensaryOpenHoursTitleSection = tw.div`
    flex
    w-full
    flex-row
    items-start
    justify-start
    gap-3
`;

export const DrawerContactDispensaryOpenHoursInfoSection = tw.div`
    ml-[30px]
    flex
    w-full
    flex-row
`;

export const DrawerContactDispensaryAddressSection = tw.div`
    flex
    flex-col
    gap-1.5
    lg:flex-row
    lg:gap-3
`;

export const DrawerContactDispensaryDrawercontainer = tw.div`
    flex
    flex-row
`;

export const DrawerContactDispensaryDrawerDaySection = tw.div`
    w-24
    lg:w-28
`;

export const DrawerContactDispensaryDrawerTimeSection = tw.div`
    [flex:1_0_0]
    w-[218px]
    lg:w-60
`;
