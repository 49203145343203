import tw from 'tailwind-styled-components';

export const OrderDetailContainer = tw.div`
bg-white 
px-5 
pb-16
pt-4
md:pb-[120px] 
md:pt-12 
md:px-[60px]
flex-1
`;

export const OrderDetailIconWithTextBox = tw.div`
    pb-5 
    md:pb-12
    flex
    gap-2
`;

export const OrderDetailWrapper = tw.div`
    flex 
    flex-col 
    items-start 
    md:flex-row  
    md:gap-6
`;
export const OrderDetailInfoBox = tw.div`
    flex 
    w-full 
    flex-col 
    items-start 
    gap-8 
    md:gap-14
`;

export const OrderDetailProductCardBox = tw.div`
    flex 
    w-full 
    flex-col 
    items-end 
    gap-4 
    pb-14 
    pt-8 
    md:py-0
`;
